<template>
  <div class="form-box">
    <div class="form">
      <van-form validate-first class="form">
        <!-- 街道 -->
        <van-field
          readonly
          clickable
          is-link
          label="街道"
          required
          :value="form.streetName"
          placeholder="请选择街道"
          @click="editDisabled?null:showStreetPicker = true"
          :rules="[{ required: true }]"
        >
          <span slot="label" class="field-label">街道</span>
        </van-field>
        <van-popup v-model="showStreetPicker" round position="bottom">
          <van-picker
            show-toolbar
            value-key="deptName"
            :columns="streetList"
            @cancel="showStreetPicker = false"
            @confirm="onConfirmStreet"
          />
        </van-popup>
        <!-- 社区 -->
        <van-field
          readonly
          clickable
          is-link
          required
          label="社区"
          :value="form.communityName"
          placeholder="请选择社区"
          @click="editDisabled?null:showPicker1 = true"
          :rules="[{ required: true }]"
        >
          <span slot="label" class="field-label">社区</span>
        </van-field>
        <van-popup v-model="showPicker1" round position="bottom">
          <van-picker
            show-toolbar
            value-key="deptName"
            :columns="shequList"
            @cancel="showPicker1 = false"
            @confirm="onConfirm1"
          />
        </van-popup>
        <!-- 姓名 -->
        <van-field
          v-model="form.realName"
          :readonly="editDisabled"
          label="姓名"
          required
          :rules="[{ required: true }]"
          placeholder="请输入用户名"
        />
        <!-- 性别 -->
        <van-field
          v-model="form.sex"
          label="性别"
          is-link
          required
          @click="editDisabled?null:showSex = true"
          :rules="[{ required: true }]"
          placeholder="请选择性别"
        />
        <van-popup v-model="showSex" round position="bottom">
          <van-picker
            show-toolbar
            value-key="dictValue"
            :columns="sexList"
            @cancel="showSex = false"
            @confirm="setSex"
          />
        </van-popup>
        <!-- 民族 -->
        <van-field
          v-model="form.nation"
          label="民族"
          is-link
          required
          :rules="[{ required: true }]"
          @click="editDisabled?null:showNation = true"
          placeholder="请输入民族"
        />
        <van-popup v-model="showNation" round position="bottom">
          <van-picker
            show-toolbar
            value-key="dictValue"
            :columns="nationList"
            @cancel="showNation = false"
            @confirm="setNation"
          />
        </van-popup>
        <!-- 身份证号码 -->
        <van-field
          v-model="form.cardId"
          label="身份证号码"
          required
          :readonly="editDisabled"
          :rules="[{ required: true }]"
          placeholder="请输入身份证号码"
        />
        <!-- 户籍地址 -->
        <van-field
          v-model="form.familyAddress"
          label="户籍地址"
          required
          :readonly="editDisabled"
          :rules="[{ required: true }]"
          placeholder="请输入户籍地址"
        />
        <!-- 户籍详细地址 -->
        <van-field
          v-model="form.familyDetailedAddress"
          label="户籍详细地址"
          required
          :readonly="editDisabled"
          :rules="[{ required: true }]"
          placeholder="请输入户籍详细地址"
        />
        <!-- 居住地址 -->
        <van-field
          v-model="form.dwellAddress"
          label="居住地址"
          required
          :readonly="editDisabled"
          :rules="[{ required: true }]"
          placeholder="请输入居住地址"
        />
        <!-- 居住详细地址 -->
        <van-field
          v-model="form.dwellDetailedAddress"
          label="居住详细地址"
          required
          :readonly="editDisabled"
          :rules="[{ required: true }]"
          placeholder="请输入居住详细地址"
        />
        <!-- 联系方式 -->
        <van-field
          v-model="form.phone"
          label="联系方式"
          required
          :readonly="editDisabled"
          :rules="[{ required: true }]"
          placeholder="请输入联系方式"
        />
        <!-- 登记时间 -->
        <van-cell title="登记时间">
          <template #right-icon>
            <span class="price-time">{{ form.createTime }}</span>
          </template>
        </van-cell>
        <!-- 审核状态 -->
        <!-- <van-field 
                    v-model="form.status" 
                    label="审核状态" 
                    required
                /> -->
        <van-cell title="审核状态">
          <template #right-icon>
            <span v-if="form.checkRes == 0" class="price-time wait">{{
              checkRes(form.checkRes)
            }}</span>
            <span v-if="form.checkRes == 1" class="price-time success">{{
              checkRes(form.checkRes)
            }}</span>
            <span v-if="form.checkRes == 2" class="price-time error">{{
              checkRes(form.checkRes)
            }}</span>
          </template>
        </van-cell>
      </van-form>
      <!-- 按钮组 -->
      <div class="btn-group" v-if="form.checkRes == 0">
        <van-button
          round
          block
          class="change"
          native-type="submit"
          type="info"
          :loading="subLoading"
          :disabled="form.checkRes == 1"
          @click="submit"
        >
          修改
        </van-button>
        <van-button round block class="cancel" type="danger" :loading="subLoading" @click="cancel">
          撤销
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { levelList } from '@/api/registerApi'

import { getNationList, getSexList, detail, revocation, update } from '@/api/registrationApi'
import { Dialog } from 'vant'
// import { submit } from '@/api/houseApi.js'

export default {
  name: 'RegistrationVoters',
  data() {
    return {
      form: {
        streetId: '',
        streetName: '',
        communityId: '',
        communityName: '',
        realName: '',
        sex: '',
        nation: '',
        cardId: '',
        familyAddress: '',
        familyDetailedAddress: '',
        dwellAddress: '',
        dwellDetailedAddress: '',
        phone: '',
        openId: JSON.parse(localStorage.getItem('yt-openId')).content,
        createTime: '',
        status: 0,
      },
      showStreetPicker: false,
      showPicker1: false,
      showPicker2: false,
      subLoading: false,
      showNation: false, // 民族弹窗
      showSex: false, // 性别弹窗显示
      streetList: [], // 街道列表
      shequList: [], // 社区列表
      nationList: [], // 民族列表
      sexList: [], // 性别下拉
    }
  },
  created() {
    this.getLevelList()
    this.getNation()
    this.getSex()
    this.getDetail()
    this.form.tenantId = this.$store.getters.tenantId
  },
  computed: {
    editDisabled() {
      if(this.form.checkRes != 1) {
        return false
      } else {
        return true
      }
    },  
    checkRes() {
      return status => {
        switch (status) {
          case 0:
            return '审核中'
          case 1:
            return '审核成功'
          case 2:
            return '审核失败'
          default:
            return '审核中'
        }
      }
    },
  },
  methods: {
    // 获取详情
    async getDetail() {
      const { id } = this.$route.query
      const res = await detail({
        id,
      })

      if (res.code == 200) {
        this.form = res.data
        this.getLevelList(res.data.streetId)
      }
    },
    // 撤销
    cancel() {
      const { id, checkRes } = this.form
      Dialog.confirm({
        message: '是否确认撤销',
      })
        .then(async () => {
          // on confirm
          const res = await revocation({
            id,
            checkRes,
          })
          if (res.code == 200) {
            this.$toast({
              type: 'success',
              message: '撤销成功',
              duration: 1500,
              onClose: () => {
                this.$router.replace('/registrationList')
              },
            })
          } else {
            this.$toast({
              type: 'fail',
              message: res.msg,
              duration: 1500,
            })
          }
        })
        .catch(() => {
          // on cancel
        })
    },
    // 选择性别
    setSex(val) {
      this.form.sex = val.dictValue
      this.showSex = false
    },
    // 查看选民登记记录
    showRecord() {
      this.$router.push('/registrationList')
    },
    // 获取民族
    async getNation() {
      const res = await getNationList()

      if (res.code == 200) {
        this.nationList = res.data
      }
    },
    // 获取性别
    async getSex() {
      const res = await getSexList()

      if (res.code == 200) {
        this.sexList = res.data
      }
    },
    // 设置民族
    setNation(val) {
      this.showNation = false
      this.form.nation = val.dictValue
    },
    // 提交表单回调
    async submit() {
      const { form } = this
      const res = await update(form)
      if (res.code == 200) {
        this.$toast({
          type: 'success',
          message: '修改成功',
          duration: 1500,
          onClose: () => {
            this.$router.replace('/registrationList')
          },
        })
      } else {
        this.$toast({
          type: 'error',
          message: res.message,
          duration: 1500,
        })
      }
    },
    getLevelList(parentId) {
      levelList({
        parentId: parentId,
        tenantId: parentId ? null : this.$store.getters.tenantId,
      }).then(res => {
        if (res.code === 200) {
          if (parentId) {
            this.shequList = res.data
          } else {
            this.streetList = res.data
          }
        }
      })
    },
    onConfirmStreet(val) {
      // this.visitor.communityId = val.id
      // this.visitor.communityName = val.deptName
      // this.visitor.tenantId = val.tenantId
      this.form.streetId = val.id
      this.form.streetName = val.deptName
      // this.form.tenantId = val.tenantId;
      this.getLevelList(val.id)
      this.showStreetPicker = false
    },
    onConfirm1(val) {
      // this.visitor.communityId = val.id
      // this.visitor.shequName = val.deptName
      this.form.communityId = val.id
      this.form.communityName = val.deptName
      this.showPicker1 = false
    },
  },
}
</script>

<style lang="scss" scoped>
.form-box {
  .form {
    .wait {
      color: #f73f55;
    }

    .success {
      color: #07c160;
    }

    .error {
      color: #f73f55;
    }

    .show-record {
      font-size: 20px;
      color: #ccc;
      text-align: center;
      margin-bottom: 30px;
    }

    .btn-group {
      padding: 0 20px;
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;

      .cancel {
        margin-left: 10px;
      }
    }
  }
}
</style>
